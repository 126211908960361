import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import pEachSeries from "p-each-series";
import { datadogLogs } from "@datadog/browser-logs";
import {
  pendingPhotoUploadKeys,
  uploadPhotoFromDatabase,
} from "./photoUtilities";

const initialState = {
  status: "idle", // 'idle' | 'pending' | 'uploading'
  currentUploadIndex: 0,
  currentUploadKey: undefined,
  pendingUploadKeys: [],
  batchUploadedKeys: [],
  uploadingSince: 0,
  currentUploadId: undefined,
  lastError: undefined,
};

const logger = {
  info: (msg, data = {}) =>
    datadogLogs.logger.info(`[PhotoUploader] ${msg}`, data),
  error: (msg, data = {}) =>
    datadogLogs.logger.error(`[PhotoUploader] [ERROR] ${msg}`, data),
};

const now = () => new Date().getTime();

const uploadPhotoIterator = (db, dispatch) => async (key, index) => {
  datadogLogs.logger.info(`Start Upload ${key}`);
  dispatch(registerUploadStart({ index, key }));
  const result = await uploadPhotoFromDatabase(db, key, dispatch);
  if (result) {
    dispatch(registerUploadComplete(key));
    datadogLogs.logger.info(`Finish Upload ${key}`);
  }
};

export const performUpload = createAsyncThunk(
  "photoUploader/performUpload",
  async ({ db }, { dispatch, getState, requestId }) => {
    const { currentUploadId, status } = getState().photoUploader;
    if (status !== "pending" || requestId !== currentUploadId) {
      return false;
    }

    const uuids = await pendingPhotoUploadKeys(db);
    dispatch(beginBatchUpload(uuids));
    await pEachSeries(uuids, uploadPhotoIterator(db, dispatch));
    return true;
  }
);

export const enumeratePendingUploads = createAsyncThunk(
  "photoUploader/enumeratePendingUploads",
  async ({ db }) => {
    return pendingPhotoUploadKeys(db);
  }
);

const photoUploaderSlice = createSlice({
  name: "photoUploader",
  initialState,
  reducers: {
    beginBatchUpload: (state, action) => {
      logger.info("Photo Batch Upload Start", {
        action: action.type,
        size: action.payload.length,
      });
      state.status = "uploading";
      state.currentUploadIndex = 0;
      state.pendingUploadKeys = action.payload;
    },
    registerUploadStart: (state, action) => {
      state.currentUploadIndex = action.payload.index;
      state.currentUploadKey = action.payload.key;
    },
    registerUploadComplete: (state, action) => {
      logger.info("Photo Upload Complete", {
        action: action.type,
        key: action.payload,
      });
      state.batchUploadedKeys = [...state.batchUploadedKeys, action.payload];
    },
    resetUploaderState: (state, action) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(performUpload.pending, (state, action) => {
        if (state.status === "idle") {
          state.status = "pending";
          state.uploadingSince = now();
          state.currentUploadId = action.meta.requestId;
          state.lastError = undefined;
        }
      })
      .addCase(performUpload.fulfilled, (state, action) => {
        if (action.payload) {
          logger.info("Photo Batch Upload Fulfilled", { action: action.type });
          Object.assign(state, initialState);
        }
      })
      .addCase(performUpload.rejected, (state, { type, error }) => {
        if (error) {
          logger.error("Photo Batch Upload Rejected", { action: type, error });
          Object.assign(state, initialState);
          state.lastError = `${error.name}: ${error.message}`;
        }
      })
      .addCase(enumeratePendingUploads.fulfilled, (state, action) => {
        if (action.payload) {
          state.pendingUploadKeys = action.payload;
        }
      });
  },
});

export const {
  beginBatchUpload,
  registerUploadStart,
  registerUploadComplete,
  resetUploaderState,
} = photoUploaderSlice.actions;

export default photoUploaderSlice.reducer;
