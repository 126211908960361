import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { useDispatch, useSelector } from "react-redux";
import {
  saveInspection,
  selectInspectionById,
} from "./features/inspections/inspectionsSlice";
import { resetUploaderState } from "./features/photos/photoUploaderSlice";
import useDatabase from "./hooks/useDatabase";

const AppListeners = () => {
  const db = useDatabase();
  const history = useHistory();
  const dispatch = useDispatch();
  const pendingResponses = useSelector(
    (state) => state.localInspection.pendingResponses
  );
  const activeInspectionIsDirty = useSelector(
    (state) => state.localInspection.status === "dirty"
  );
  const activeInspectionUuid = useSelector(
    (state) => state.localInspection.uuid
  );
  const activeInspection = useSelector((state) =>
    activeInspectionUuid
      ? selectInspectionById(state, activeInspectionUuid)
      : null
  );

  /**
   * Save any in-flight work.
   *
   * An active, unsaved draft is identified via the dirty flag on the localInspection
   * Redux slice. If one of those exists, the user has unsaved work that is at risk
   * of being lost if the Redux state is lost, as is the case if the app is paused.
   */
  const saveDraftWork = useCallback(() => {
    if (
      activeInspection &&
      activeInspection.status === "draft" &&
      activeInspectionIsDirty
    ) {
      const persistableInspectionData = { ...activeInspection };
      persistableInspectionData.updated_at = new Date().toJSON();
      persistableInspectionData.responses = pendingResponses;
      // TODO: refactor percentComplete to be available outside of <Inspection/>
      // persistableInspectionData.percent_complete = todo();
      dispatch(saveInspection({ db, inspection: persistableInspectionData }));
    }
  }, [activeInspection, activeInspectionIsDirty, pendingResponses]);

  useEffect(() => {
    /**
     * This listener navigates the user to the correct place when they have arrived
     * via a Universal Link.
     */
    App.addListener("appUrlOpen", async (event) => {
      // When the URL comes in as https://inspections.servusconnect.com/launch?req_id=123#foo,
      // we want to browse to the "path plus query and fragment", i.e. "/launch?req_id=123#foo"
      // so that we don't lose any information. Note that if a custom-scheme is used, the
      // protocol will be that scheme, and origin will be null, so we set it to https.
      const url = new URL(event.url);
      url.protocol = "https";
      const pathPlus = url.href.replace(url.origin, "");

      if (pathPlus) {
        try {
          await Browser.close();
        } catch {
          // ignore
        }
        history.replace(pathPlus);
      }
    });

    /**
     * This listener allows us to perform actions upon app deactivation, such as
     * saving the user's in-flight work.
     */
    App.addListener("appStateChange", (state) => {
      if (!state.isActive) {
        saveDraftWork();
      }
      if (state.isActive) {
        dispatch(resetUploaderState());
      }
    });

    return () => App.removeAllListeners();
  }, [saveDraftWork]);

  return null;
};

export default AppListeners;
